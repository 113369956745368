import React from 'react';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { WidgetEnvironment } from '../../../common/sgwt-widgets-utils';
import { HELPCENTER_STEPS, ISgwtHelpCenterAction } from '../sgwt-help-center.types';
import { MY_CASES_ENDPOINTS } from '../shared';

interface PanelFooterProps extends ITranslatorProps {
  environment: WidgetEnvironment;
  expanded: boolean;
  hasFeedback: boolean;
  hasForm: boolean;
  next: (action: ISgwtHelpCenterAction) => void;
  noConsole: boolean;
  step: HELPCENTER_STEPS;
}

export const PanelFooter = ({
  environment,
  expanded,
  hasFeedback,
  hasForm,
  next,
  noConsole,
  step,
  translator,
}: PanelFooterProps) => {
  if (step === HELPCENTER_STEPS.SUPPORT_CASES) {
    return (
      <div className="card-footer pb-3 px-4 row">
        <div className="d-flex flex-wrap gap-8px">
          <div className="col">
            <a href={MY_CASES_ENDPOINTS[environment].portal} className="btn btn-link btn-lg btn-block" target="_blank">
              {translator.translate('supportCases.see-all')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  const showChatBot = !noConsole && customElements.get('sgwt-sgbot-ms');
  const showHint = showChatBot || hasForm;
  const cols = expanded ? 'col mt-2' : 'col-12 mt-2';
  return (
    <div className="card-footer pb-3 px-4 row">
      {showHint && <p className="text-secondary mb-1 col-12">{translator.translate('message.openButtonHint')}</p>}
      {showChatBot && (
        <div className={`${cols}${expanded && (hasForm || hasFeedback) ? ' pr-1 pe-1' : ''}`}>
          <button
            className="btn btn-outline-primary btn-lg btn-block"
            type="button"
            onClick={(): void => {
              next({ step: HELPCENTER_STEPS.CHAT });
            }}
          >
            {translator.translate('chatOpenButtonLabel')}
          </button>
        </div>
      )}
      {hasFeedback && (
        <div
          className={`${cols}${expanded && showChatBot ? ' pl-1 ps-1' : ''}${
            expanded && hasFeedback ? ' pr-1 pe-1' : ''
          }`}
        >
          <button
            className="btn btn-light btn-lg btn-block"
            type="button"
            onClick={(): void => {
              next({ step: HELPCENTER_STEPS.FEEDBACK });
            }}
          >
            {translator.translate('feedback.title')}
          </button>
        </div>
      )}
      {hasForm && (
        <div className={`${cols}${expanded && (showChatBot || hasForm) ? ' pl-1 ps-1' : ''}`}>
          <button
            className="btn btn-primary btn-lg btn-block"
            type="button"
            onClick={(): void => {
              next({ step: HELPCENTER_STEPS.FORM });
            }}
          >
            {translator.translate('message.openButtonLabel')}
          </button>
        </div>
      )}
    </div>
  );
};
