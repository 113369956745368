import React, { MutableRefObject, useEffect, useRef } from 'react';
import { SidePanel } from '../../../../common/components/SidePanel';
import { registerHelpCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { WidgetEnvironment } from '../../../../common/sgwt-widgets-utils';
import { useLoadWidget } from '../../../../common/use-load-widget';
import {
  HELPCENTER_QUIT,
  HELPCENTER_STEPS,
  IHelpCenterRemoteConfiguration,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterKnowledge,
} from '../../sgwt-help-center.types';
import { PanelHeader } from '../PanelHeader';
import {
  MycSelfcaseCreationWidgetHTMLElement,
  setSelfcaseWidgetConfiguration,
  WIDGET_URL_HOMOLOGATION,
  WIDGET_URL_PRODUCTION,
} from './mycases.types';

interface MyCasesFormProps extends ITranslatorProps {
  changeExpandableState: () => void;
  close: () => void;
  helpCenterConfiguration: IHelpCenterRemoteConfiguration | null;
  environment: WidgetEnvironment;
  expanded: boolean;
  hasMessageOnly: boolean;
  isLoading: boolean;
  knowledge: ISgwtHelpCenterKnowledge | null;
  next: (action: ISgwtHelpCenterAction) => void;
  previous: (nb?: number) => void;
  quit: HELPCENTER_QUIT;
  step: HELPCENTER_STEPS;
}

const WIDGET_NAME = 'myc-selfcase-creation-widget';

export const MyCasesForm = (props: MyCasesFormProps) => {
  const isProd = props.environment === 'production';
  const widgetUrl = isProd ? WIDGET_URL_PRODUCTION : WIDGET_URL_HOMOLOGATION;
  setSelfcaseWidgetConfiguration(isProd);

  const mycasesService = props.helpCenterConfiguration?.communication?.mycasesService;

  const widget: MutableRefObject<MycSelfcaseCreationWidgetHTMLElement | undefined> =
    useRef<MycSelfcaseCreationWidgetHTMLElement>();

  const { isLoading } = useLoadWidget(WIDGET_NAME, widgetUrl);

  useEffect(() => {
    if (mycasesService) {
      registerHelpCenterEvent('contact-us.mycases.open', { service: mycasesService });
    } else {
      registerHelpCenterEvent('contact-us.mycases.error.no-service');
    }
  }, [mycasesService]);

  useEffect(() => {
    const { current } = widget;
    const redirectToHome = () => {
      registerHelpCenterEvent('contact-us.mycases.event', { action: 'redirectToHome' });
      props.previous();
    };

    current?.addEventListener('redirectToHome', redirectToHome);

    return () => current?.removeEventListener('redirectToHome', redirectToHome);
  }, [props]);

  if (!mycasesService) {
    return <div>Error</div>;
  }

  const handleClose = (fromClickOut: boolean) => {
    if (!fromClickOut) {
      props.close();
    }
  };

  // The ⬅️ button in the Header can be used either to go back in the MyCases workflow,
  // or go back to the Home panel of the Help-Center, depending on the response of the widget.
  const goBack = () => {
    if (widget.current) {
      widget.current.goBack().subscribe({
        // Inform the widget that the user wants to go back. If the widget answers with `true`, it
        // means that the user is at the beginning of the workflow, and we can go back to the Home panel.
        next: (quit: boolean) => {
          if (quit) {
            registerHelpCenterEvent('contact-us.mycases.back.quit');
            props.previous();
          } else {
            registerHelpCenterEvent('contact-us.mycases.back.stay');
          }
        },
      });
    } else {
      // Widget is not found.
      props.previous();
    }
  };

  return (
    <SidePanel
      widget="sgwt-help-center"
      onClose={handleClose}
      expanded={props.expanded}
      title={props.translator.translate('panel.contact-us')}
    >
      <PanelHeader {...props} previous={goBack} hasSearch={false} />
      <div className="card-body px-0 py-0">
        {isLoading && <div className="blinker blinker-lg m-4" />}
        <myc-selfcase-creation-widget
          id="sgwt-help-center-my-cases"
          ref={widget}
          config-id={mycasesService}
        ></myc-selfcase-creation-widget>
      </div>
    </SidePanel>
  );
};
