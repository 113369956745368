import React from 'react';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { registerHelpCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import {
  HELPCENTER_STEPS,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterSearchResult,
  ISgwtHelpCenterTopic,
} from '../../sgwt-help-center.types';
import { getTopicPropertyById } from '../../shared/utils';

interface ITopicProps extends ITranslatorProps {
  hasForm: boolean;
  next: (action: ISgwtHelpCenterAction) => void;
  noImprovement: boolean;
  searchResults: ISgwtHelpCenterSearchResult[] | null;
  topicId?: string;
  topics: ISgwtHelpCenterTopic[] | null;
}

const getTopicToDisplay = (
  topics: ISgwtHelpCenterTopic[] | null,
  searchResults: ISgwtHelpCenterSearchResult[] | null,
  topicId?: string,
): ISgwtHelpCenterTopic | null => {
  if (!topicId) {
    return null;
  }
  const results = (topics || []).filter((topic) => topic.id === topicId);
  if (results.length > 0) {
    return results[0];
  }
  if (searchResults) {
    const filtering = searchResults.filter((topic) => topic.item.id === topicId);
    if (filtering.length > 0) {
      return filtering[0].item;
    }
  }
  return null;
};

export const Topic: React.FC<ITopicProps> = ({
  hasForm = false,
  next,
  noImprovement = false,
  searchResults,
  topicId,
  topics = null,
  translator,
}: ITopicProps) => {
  const topic: ISgwtHelpCenterTopic | null = getTopicToDisplay(topics, searchResults, topicId);
  const clickFeedbackLink = () => {
    next({ step: HELPCENTER_STEPS.FORM, topicId });
    registerHelpCenterEvent(
      'panel.click-improve-this-topic',
      topic ? { topicId: topic.id, title: topic.title } : undefined,
    );
  };

  return (
    <div className="card-body-topic">
      <h5
        className="mb-2"
        dangerouslySetInnerHTML={{
          __html: topic && topic.title ? topic.title : '',
        }}
      />
      <p
        className="mb-3"
        dangerouslySetInnerHTML={{
          __html: topic && topic.content ? topic.content : '',
        }}
      />
      {hasForm && !noImprovement && (
        <p className="feedback-link fw-semibold mb-3">
          <button onClick={clickFeedbackLink} className="btn btn-link px-0">
            <i className="sgwt-widgets-icon icon-sm mr-1 me-1" aria-hidden="true">
              <SvgIcon type="mail_outline" />
            </i>
            {` ${translator.translate('topicFeedbackLink')}`}
          </button>
        </p>
      )}
      {topics &&
      topic &&
      topic.relatedTopics &&
      topic.relatedTopics instanceof Array &&
      topic.relatedTopics.length !== 0 ? (
        <div>
          <p className="card-body-introduction mb-3">{translator.translate('topicRelatedIntroduction')}</p>
          {topic.relatedTopics.map((id: string): JSX.Element => {
            const link = getTopicPropertyById(topics, id, 'externalLink');
            if (link) {
              return (
                <a
                  key={`topic-${id}`}
                  className="position-relative p-3 text-left text-start w-100 card text-secondary card-bordering card-bordered position-relative d-flex mb-2 sgwt-help-center-topic"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span dangerouslySetInnerHTML={{ __html: getTopicPropertyById(topics, id, 'title') }} />
                </a>
              );
            }

            return (
              <button
                key={`topic-${id}`}
                className="position-relative p-3 text-left text-start w-100 card text-secondary card-bordering card-bordered position-relative d-flex mb-2 sgwt-help-center-topic"
                onClick={() => next({ step: HELPCENTER_STEPS.TOPIC, topicId: id })}
              >
                <span dangerouslySetInnerHTML={{ __html: getTopicPropertyById(topics, id, 'title') }} />
              </button>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
