import React from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import {
  HELPCENTER_STEPS,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterCategory,
  ISgwtHelpCenterKnowledge,
  ISgwtHelpCenterSearchResult,
  ISgwtHelpCenterTopic,
} from '../../sgwt-help-center.types';
import { hasAccessToMyCases } from '../mycases/mycases.utils';
import { SupportCases } from '../mycases/SupportCases';
import { Categories } from './Categories';
import { Topic } from './Topic';
import { Topics } from './Topics';

interface KnowledgeBodyProps extends ITranslatorProps {
  categoryId?: string;
  close: () => void;
  emitEvent: (name: string, detail: any) => void;
  expanded: boolean;
  hasForm: boolean;
  hasIntroductionTour: boolean;
  isLoading: boolean;
  knowledge: ISgwtHelpCenterKnowledge | null;
  next: (action: ISgwtHelpCenterAction) => void;
  noImprovement: boolean;
  searchResults: ISgwtHelpCenterSearchResult[] | null;
  searchValue?: string;
  startIntroductionTour: () => void;
  step: HELPCENTER_STEPS;
  topicId?: string;
  waitForAdditionalSearchResults: boolean;
  widgetConfiguration: WidgetConfiguration;
}

export const KnowledgeBody = (props: KnowledgeBodyProps) => {
  const {
    categoryId,
    close,
    emitEvent,
    expanded = false,
    hasForm = false,
    hasIntroductionTour = false,
    knowledge = null,
    next,
    noImprovement = false,
    searchResults = null,
    searchValue,
    startIntroductionTour,
    step = HELPCENTER_STEPS.HOME,
    topicId,
    translator,
    waitForAdditionalSearchResults,
    widgetConfiguration,
  } = props;

  const categories: ISgwtHelpCenterCategory[] | null =
    knowledge && knowledge.categories && knowledge.categories instanceof Array ? knowledge.categories : null;
  const topics: ISgwtHelpCenterTopic[] | null =
    knowledge && knowledge.topics && knowledge.topics instanceof Array ? knowledge.topics : null;

  const showMyCases = hasAccessToMyCases(widgetConfiguration);

  return (
    <div className="card-body px-4 py-0 mb-4">
      {step === HELPCENTER_STEPS.TOPIC && (
        <Topic
          hasForm={hasForm}
          next={next}
          noImprovement={noImprovement}
          searchResults={searchResults}
          topicId={topicId}
          topics={topics}
          translator={translator}
        />
      )}
      {showMyCases && (
        <SupportCases next={next} step={step} translator={translator} widgetConfiguration={widgetConfiguration} />
      )}
      {(step === HELPCENTER_STEPS.HOME || step === HELPCENTER_STEPS.CATEGORY) && (
        <Categories
          categories={categories}
          categoryId={categoryId}
          emitEvent={emitEvent}
          expanded={expanded}
          next={next}
          step={step}
          translator={translator}
        />
      )}
      {(step === HELPCENTER_STEPS.HOME || step === HELPCENTER_STEPS.CATEGORY || step === HELPCENTER_STEPS.SEARCH) && (
        <Topics
          categoryId={categoryId}
          close={close}
          hasIntroductionTour={hasIntroductionTour}
          emitEvent={emitEvent}
          next={next}
          searchResults={searchResults}
          searchValue={searchValue}
          startIntroductionTour={startIntroductionTour}
          step={step}
          topics={topics}
          translator={translator}
          waitForAdditionalSearchResults={waitForAdditionalSearchResults}
        />
      )}
    </div>
  );
};
