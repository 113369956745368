import { WidgetConfiguration } from '@sg-widgets/shared-core';
import {
  isLoadedFromInternalProduction,
  whichEnvironment,
  WidgetEnvironment,
} from '../../../common/sgwt-widgets-utils';
import { CommunicationType } from './communication.types';

// const HOMOLOGATION_API = 'http://localhost:9999/api/v1';
const DEVELOPMENT_API = 'https://sgwt-api-sgwt-help-center-dev.sgmarkets.world.socgen/api/v1';

// Homologation API, with or without SG APIM (for exposition)
const INTERNAL_HOMOLOGATION_API = 'https://sgwt-api-sgwt-help-center-uat.sgmarkets.world.socgen/api/v1';
const HOMOLOGATION_API = 'https://api-s-uat.sgmarkets.com/widget/webtoolkit/help-center/v1';

// Production API, with or without SG APIM (for exposition)
const INTERNAL_PRODUCTION_API = 'https://sgwt-api-sgwt-help-center.sgmarkets.world.socgen/api/v1';
const PRODUCTION_API = 'https://api-s.sgmarkets.com/widget/webtoolkit/help-center/v1';

type EndpointCategory = 'sg-connect-v2' | 'help-center-api' | 'help-center-anonymous-api';
type ExtendedEnvironment =
  | 'development'
  | 'homologation'
  | 'production'
  | 'internal_homologation'
  | 'internal_production';

export const ENVIRONMENT_ENDPOINTS: Record<EndpointCategory, Record<WidgetEnvironment, Record<string, string>>> = {
  'sg-connect-v2': {
    development: {
      authenticationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
    },
    homologation: {
      authenticationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
    },
    production: {
      authenticationEndpoint: 'https://sso.sgmarkets.com/sgconnect',
    },
  },
  'help-center-api': {
    development: {
      apiEndpoint: `${DEVELOPMENT_API}/help-center`,
    },
    homologation: {
      apiEndpoint: `${HOMOLOGATION_API}/help-center`,
    },
    production: {
      apiEndpoint: `${PRODUCTION_API}/help-center`,
    },
  },
  // TODO remove them when the anonymous API is not used anymore
  'help-center-anonymous-api': {
    development: {
      apiEndpoint: `${DEVELOPMENT_API}/help-center`,
    },
    homologation: {
      apiEndpoint: `${INTERNAL_HOMOLOGATION_API}/help-center`,
    },
    production: {
      apiEndpoint: `${PRODUCTION_API}/help-center`,
    },
  },
};

const COMMUNICATION_ENDPOINTS: Record<CommunicationType, Record<ExtendedEnvironment, string>> = {
  mail: {
    development: `${DEVELOPMENT_API}/communication-hub/mail`,
    internal_homologation: `${INTERNAL_HOMOLOGATION_API}/communication-hub/mail`,
    homologation: `${HOMOLOGATION_API}/communication-hub/mail`,
    internal_production: `${INTERNAL_PRODUCTION_API}/communication-hub/mail`,
    production: `${PRODUCTION_API}/communication-hub/mail`,
  },
  unity: {
    development: `${DEVELOPMENT_API}/communication-hub/unity`,
    internal_homologation: `${INTERNAL_HOMOLOGATION_API}/communication-hub/unity`,
    homologation: `${HOMOLOGATION_API}/communication-hub/unity`,
    internal_production: `${INTERNAL_PRODUCTION_API}/communication-hub/unity`,
    production: `${PRODUCTION_API}/communication-hub/unity`,
  },
  impulse: {
    development: `${DEVELOPMENT_API}/communication-hub/impulse`,
    internal_homologation: `${INTERNAL_HOMOLOGATION_API}/communication-hub/impulse`,
    homologation: `${HOMOLOGATION_API}/communication-hub/impulse`,
    internal_production: `${INTERNAL_PRODUCTION_API}/communication-hub/impulse`,
    production: `${PRODUCTION_API}/communication-hub/impulse`,
  },
  feedback: {
    development: `${DEVELOPMENT_API}/feedback`,
    internal_homologation: `${INTERNAL_HOMOLOGATION_API}/feedback`,
    homologation: `${HOMOLOGATION_API}/feedback`,
    internal_production: `${INTERNAL_PRODUCTION_API}/feedback`,
    production: `${PRODUCTION_API}/feedback`,
  },
  // No, but yes...
  mycases: {
    development: `${DEVELOPMENT_API}/communication-hub/mail`,
    internal_homologation: `${INTERNAL_HOMOLOGATION_API}/communication-hub/mail`,
    homologation: `${HOMOLOGATION_API}/communication-hub/mail`,
    internal_production: `${INTERNAL_PRODUCTION_API}/communication-hub/mail`,
    production: `${PRODUCTION_API}/communication-hub/mail`,
  },
  none: {
    development: `${DEVELOPMENT_API}/communication-hub/mail`,
    internal_homologation: `${INTERNAL_HOMOLOGATION_API}/communication-hub/mail`,
    homologation: `${HOMOLOGATION_API}/communication-hub/mail`,
    internal_production: `${INTERNAL_PRODUCTION_API}/communication-hub/mail`,
    production: `${PRODUCTION_API}/communication-hub/mail`,
  },
};

export function getCommunicationEndpoint(
  type: CommunicationType,
  widgetConfiguration: WidgetConfiguration,
  applicationId?: string,
): string {
  let environment: string = whichEnvironment(widgetConfiguration);
  if (environment === 'production' && isLoadedFromInternalProduction('sgwt-help-center')) {
    environment = 'internal_production';
  }
  const endpoint = COMMUNICATION_ENDPOINTS[type][environment as ExtendedEnvironment];
  return endpoint + (applicationId ? `?application-id=${applicationId}` : '');
}

/**
 * API for MyCases
 */
interface MyCasesEndpoints {
  api: string;
  portal: string;
}

export const MY_CASES_ENDPOINTS: Record<WidgetEnvironment, MyCasesEndpoints> = {
  development: {
    api: 'https://api-z-dev.sgmarkets.com/aks-uat-2/services/mycases/private/api/v1/cases/_do-search-cases',
    portal: 'https://mycases-uat-2.sgmarkets.com',
  },
  homologation: {
    api: 'https://api-z-dev.sgmarkets.com/aks-uat-2/services/mycases/private/api/v1/cases/_do-search-cases',
    portal: 'https://mycases-uat-2.sgmarkets.com',
  },
  // TODO
  production: {
    api: 'https://api-z.sgmarkets.com/services/mycases/private/api/v1/cases/_do-search-cases',
    portal: 'https://mycases.sgmarkets.com',
  },
};
