import { CommunicationType, UnityImpactedEnvironment, UnityType, Value_12, Value_123 } from './shared';

export enum HELPCENTER_STEPS {
  HOME,
  CATEGORY,
  SEARCH,
  TOPIC,
  FORM,
  FEEDBACK,
  CHAT,
  SUPPORT_CASES,
}

export type HELPCENTER_QUIT = '' | 'close' | 'previous';

export interface ISgwtHelpCenterEnvironmentInfo {
  authenticationEndpoint?: string | null;
  userProfileLink?: string | null;
  emailEndpoint?: string | null;
}

export interface ISgwtHelpCenterJwtClaims {
  // partial
  sub: string; // email
}

export interface ISgwtHelpCenterError {
  contact: string;
  technicalDetails: string;
}

export interface ISgwtHelpCenterAction {
  step: HELPCENTER_STEPS;
  categoryId?: string | null;
  topicId?: string | null;
  searchValue?: string | null;
  chatContext?: string | null;
  title?: string | null;
  rating?: number | null;
  formValues?: HelpCenterFormInput | null;
}

export interface ISgwtHelpCenterUser {
  username: string;
  email: string;
}

export interface ISgwtHelpCenterCategory {
  id: string;
  title: string;
  icon: string;
  internal?: boolean | null;
  subCategories?: ISgwtHelpCenterCategory[] | null;
}

export interface ISgwtHelpCenterTopic {
  id: string;
  categoryId: string;
  title: string;
  content?: string;
  visibleOnHome?: boolean | null;
  highlight?: boolean | null;
  internal?: boolean | null;
  relatedTopics?: string[] | null;
  externalLink?: string | null;
}

export interface ISgwtHelpCenterSearchResult {
  item: ISgwtHelpCenterTopic & { origin: 'knowledge' | 'extended-search' };
  score: number;
}

export interface ISgwtHelpCenterSearchExternalResult {
  title: string;
  content?: string;
  externalLink?: string | null;
}

export interface ISgwtHelpCenterMessageTopic {
  topic: string;
  sendTo?: string | null;
}

export interface ISgwtHelpCenterKnowledge {
  categories?: ISgwtHelpCenterCategory[] | null;
  topics?: ISgwtHelpCenterTopic[] | null;
}

// ------------------- CONFIGURATION

export interface IHelpCenterCommunicationRemoteConfiguration {
  application: string;
  medium: CommunicationType;
  // For Screenshot
  allowScreenshot: boolean;
  automaticScreenshot: boolean;
  // For Mail
  mail: string | null;
  // For Impulse
  impulseAssignmentGroup: string[];
  impulseCaseType: UnityType[] | UnityType;
  impulseCmdbCi: string[];
  impulseConfidentiality: Value_12[] | Value_12;
  impulseImpact: Value_123[] | Value_123;
  impulseImpactedEnvironment: UnityImpactedEnvironment[];
  impulsePeopleToNotify: string;
  impulseSymptomCategory: string[];
  impulseUrgency: Value_123[] | Value_123;
  // For MyCases
  mycasesService: string | null;
}

export interface IHelpCenterFeedbackRemoteConfiguration {
  enabled: boolean;
  requestFeedback: boolean;
}

export interface IHelpCenterRemoteConfiguration {
  feedback: IHelpCenterFeedbackRemoteConfiguration;
  communication: IHelpCenterCommunicationRemoteConfiguration | null;
}

export interface MailFormInput {
  medium?: 'mail';
  topic?: string;
  content?: string;
}

export interface UnityFormInput {
  medium?: 'unity';
  topic?: string;
  content?: string;
  assignmentGroup?: string;
  caseType?: UnityType;
  cmdbCi?: string;
  impact?: Value_123;
  impactedEnvironment?: UnityImpactedEnvironment;
  symptomCategory?: string;
  urgency?: Value_123;
  whyHighImpact?: string;
}

export type HelpCenterFormInput = MailFormInput | UnityFormInput;
