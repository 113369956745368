import React, { useRef, useState } from 'react';
import { default as html2canvas, Options } from 'html2canvas';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { registerHelpCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { IHelpCenterRemoteConfiguration } from '../../sgwt-help-center.types';

interface ScreenshotProps extends ITranslatorProps {
  configuration: IHelpCenterRemoteConfiguration | null;
  onCapture: (capture: string | null) => void;
  screenshot: string | null;
}

export async function screenshotPage(externalCall: boolean): Promise<string> {
  const opts: Partial<Options> = {
    allowTaint: false,
    logging: true,
    useCORS: true,
    ignoreElements: (htmlElement: Element) => {
      // TODO Issue with Account Center that hide and redisplay the Help Center...
      if (/sgwt-/.test(htmlElement.tagName.toLowerCase())) {
        // if (htmlElement.tagName.toLowerCase() === 'sgwt-help-center') {
        return true;
      }
      return false;
    },
    removeContainer: true,
  };

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const html2canvasFn: typeof html2canvas = require('html2canvas');

  try {
    const canvas = await html2canvasFn(document.body, opts);
    registerHelpCenterEvent(`${externalCall ? 'api' : 'contact-us'}.screen-capture`);
    return canvas.toDataURL('image/png');
  } catch (error) {
    console.error('Could not generate screenshot for contact-us', error);
    registerHelpCenterEvent(`${externalCall ? 'api' : 'contact-us'}.error.screen-capture`, {
      message: (error as Error).message,
    });
    throw error;
  }
}

export const Screenshot = ({ configuration, onCapture, screenshot, translator }: ScreenshotProps) => {
  const [takingCapture, setTakingCapture] = useState(false);
  const initialScreenshotRef = useRef(true);
  const automaticScreenshot =
    configuration && configuration.communication && configuration.communication.automaticScreenshot;

  const generateScreenshot = () => {
    setTakingCapture(true);
    screenshotPage(false)
      .then((dataUrl) => {
        onCapture(dataUrl);
      })
      .finally(() => setTakingCapture(false));
  };

  const removeScreenshot = () => onCapture(null);

  if (initialScreenshotRef.current && automaticScreenshot) {
    initialScreenshotRef.current = false;
    generateScreenshot();
  }

  return (
    <div>
      {takingCapture ? (
        <div className="spinner-grow blinker blinker-lg text-nowrap mb-3" role="status">
          {translator.translate('attachments.screenshot.taking')}
        </div>
      ) : screenshot ? (
        <div className="mb-3">
          <label className="d-block text-secondary">{translator.translate('attachments.title')}</label>
          <div className="d-flex">
            <img src={screenshot} alt="Screenshot" style={{ maxHeight: '122px' }} className="border" />
            <button
              title={translator.translate('attachments.remove')}
              onClick={removeScreenshot}
              className="btn btn-icon btn-outline-secondary ms-1"
              type="button"
            >
              <SvgIcon type="close" />
            </button>
          </div>
        </div>
      ) : (
        <button className="btn btn-flat-secondary" type="button" onClick={generateScreenshot}>
          <SvgIcon type="aspect_ratio" height={16} className="mr-1 me-1" />
          {translator.translate('attachments.screenshot.add')}
        </button>
      )}
    </div>
  );
};
