import React from 'react';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { registerHelpCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import {
  HELPCENTER_STEPS,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterSearchResult,
  ISgwtHelpCenterTopic,
} from '../../sgwt-help-center.types';

interface TopicsProps extends ITranslatorProps {
  categoryId?: string;
  close: () => void;
  emitEvent: (name: string, detail: any) => void;
  hasIntroductionTour: boolean;
  next: (action: ISgwtHelpCenterAction) => void;
  searchResults: ISgwtHelpCenterSearchResult[] | null;
  searchValue?: string;
  startIntroductionTour: () => void;
  step: HELPCENTER_STEPS;
  topics: ISgwtHelpCenterTopic[] | null;
  waitForAdditionalSearchResults: boolean;
}

export const Topics = ({
  categoryId,
  close,
  emitEvent,
  hasIntroductionTour = false,
  next,
  searchResults = null,
  searchValue,
  startIntroductionTour,
  step = HELPCENTER_STEPS.HOME,
  topics = null,
  translator,
  waitForAdditionalSearchResults,
}: TopicsProps) => {
  let elements: ISgwtHelpCenterTopic[] | null = null;

  if (topics) {
    if (step === HELPCENTER_STEPS.HOME) {
      // On Home screen, we display the "Suggested topics" if they have "Hightlight" or "Home" property.
      elements = topics
        .filter((topic) => !!topic.visibleOnHome || !!topic.highlight)
        .sort((t1, t2) => {
          // We calculate the "score" of a topic. "Highlight" property is more important than just "Home".
          const score1 = (t1.highlight ? 2 : 0) + (t1.visibleOnHome ? 1 : 0);
          const score2 = (t2.highlight ? 2 : 0) + (t2.visibleOnHome ? 1 : 0);
          return score2 - score1;
        });
    } else if (step === HELPCENTER_STEPS.CATEGORY && categoryId) {
      elements = topics.filter((topic) => topic.categoryId === categoryId);
    } else if (step === HELPCENTER_STEPS.SEARCH && searchValue) {
      if (searchResults && searchResults.length !== 0) {
        elements = searchResults.map((result) => ({ ...result.item }));
      }
    }
  }

  const items: JSX.Element[] | null =
    elements && elements.length !== 0
      ? elements.map((topic: ISgwtHelpCenterTopic): JSX.Element => {
          const externalLink = topic.externalLink;
          const javascriptLink = externalLink && /^javascript:/i.test(externalLink);

          const clickHandler = () => {
            if (externalLink) {
              emitEvent('external-link', { topicId: topic.id, url: externalLink });
              registerHelpCenterEvent('panel.click-external-link', { topicId: topic.id, url: externalLink });
            } else {
              emitEvent('topic', { topicId: topic.id, title: topic.title });
              next({
                step: HELPCENTER_STEPS.TOPIC,
                topicId: topic.id,
                title: topic.title,
              });
              registerHelpCenterEvent(`panel.click-${step === HELPCENTER_STEPS.HOME ? 'suggested-topic' : 'topic'}`, {
                topicId: topic.id,
                title: topic.title,
              });
            }
          };

          return externalLink ? (
            <a
              href={externalLink}
              onClick={clickHandler}
              className="position-relative p-3 text-left text-start w-100 card text-secondary card-bordering card-bordered position-relative d-flex mb-2 sgwt-help-center-topic"
              target={javascriptLink ? '' : '_blank'}
              rel="noreferrer"
              aria-label={topic.title}
            >
              <span dangerouslySetInnerHTML={{ __html: topic.title }} />
            </a>
          ) : (
            <button
              className="position-relative p-3 text-left text-start w-100 card text-secondary card-bordering card-bordered position-relative d-flex mb-2 sgwt-help-center-topic"
              aria-label={topic.title}
              onClick={clickHandler}
              type="button"
            >
              <span dangerouslySetInnerHTML={{ __html: topic.title }} />
            </button>
          );
        })
      : null;

  // Add IntroJS link if asked...
  const introJs: JSX.Element | null = hasIntroductionTour ? (
    <li>
      <button
        type="button"
        className="w-100 card text-secondary card-bordering card-bordered mb-2 sgwt-help-center-topic"
        onClick={startIntroductionTour}
        aria-label={translator.translate('specialTopicIntroJs')}
      >
        <div className="card-body p-3 font-weight-bold fw-bold">
          <i aria-hidden="true" className="sgwt-widgets-icon icon-sm text-danger mr-1 me-1">
            <SvgIcon type="play_circle_filled" />
          </i>
          {translator.translate('specialTopicIntroJs')}
        </div>
      </button>
    </li>
  ) : null;

  const splashScreen = document.querySelector('sgwt-splash-screen');
  const showHistory = () => {
    (splashScreen as any).showHistory();
    close();
    registerHelpCenterEvent('panel.click-see-important-messages-again');
  };

  const importantMessages: JSX.Element | null = splashScreen ? (
    <li>
      <button
        type="button"
        className="w-100 card text-secondary card-bordering card-bordered mb-2 sgwt-help-center-topic"
        onClick={showHistory}
        aria-label={translator.translate('importantMessages')}
      >
        <div className="card-body p-3 font-weight-bold fw-bold">
          <i aria-hidden="true" className="sgwt-widgets-icon icon-sm text-danger mr-1 me-1">
            <SvgIcon type="history" />
          </i>
          {translator.translate('importantMessages')}
        </div>
      </button>
    </li>
  ) : null;

  return (
    <div className="card-body-topics my-2">
      {(step === HELPCENTER_STEPS.HOME || (step === HELPCENTER_STEPS.SEARCH && searchValue)) && (
        <h3 className="h6 card-body-introduction mb-3">
          {searchValue
            ? !searchResults || searchResults.length === 0
              ? translator.translate('searchZeroResultIntroduction')
              : `${searchResults.length} ${translator.translate('searchResultIntroduction')}`
            : step === HELPCENTER_STEPS.HOME && items
              ? translator.translate('homeTopicsIntroduction')
              : ''}
        </h3>
      )}
      <ul className="list-unstyled">
        {step === HELPCENTER_STEPS.HOME && introJs}
        {step === HELPCENTER_STEPS.HOME && importantMessages}
        {items && items.map((item, index) => <li key={`list-${index}`}>{item}</li>)}
        {waitForAdditionalSearchResults && (
          <div className="spinner-grow spinner-grow-sm blinker blinker-lg text-nowrap">
            {translator.translate('searchWaitAdditionalResults')}
          </div>
        )}
      </ul>
    </div>
  );
};
