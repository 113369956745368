interface SelfcaseWidgetConfig {
  allowedFileSize: string;
  allowedFileRequestSize: string;
  SGDocs: {
    workspaceId: string;
    workspaceIdCMT: string;
    modelId: string;
  };
  api: {
    cmtUrl: string;
    sgDocsUrl: string;
  };
  toggleFeature: {
    isRoutingMatrixActivated: boolean;
  };
  selfcase: {
    roleId: string;
    configUrl: string;
  };
  mycasesBaseUrl: string;
  unauthorizeUrl: string;
}

export interface MycSelfcaseCreationWidgetHTMLElement extends HTMLElement {
  goBack: () => {
    subscribe: (observer: { next: (quit: boolean) => void }) => void;
  };
}

declare global {
  interface Window {
    selfcaseWidgetConfig?: SelfcaseWidgetConfig;
  }

  // Custom type definition for myc-selfcase-creation-widget
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      // : React.DetailedHTMLProps<
      // React.HTMLAttributes<MycSelfcaseCreationWidgetHTMLElement>,
      // MycSelfcaseCreationWidgetHTMLElement
      // > &
      'myc-selfcase-creation-widget': {
        id?: string;
        ref?: React.MutableRefObject<MycSelfcaseCreationWidgetHTMLElement | undefined>;
        'config-id'?: string;
      };
    }
  }
}

// From: https://sgithub.fr.world.socgen/MYC/myc-selfcase-creation-widget/tree/develop?tab=readme-ov-file#environment-variables-to-be-added-in-host-application

const selfcaseWidgetConfigHomologation: SelfcaseWidgetConfig = {
  allowedFileSize: '10000000',
  allowedFileRequestSize: '10000000',
  SGDocs: {
    workspaceId: '1a0d6ae5-1198-4b01-9f06-cd95f5a41b9b',
    workspaceIdCMT: 'b9e02018-f9bf-437b-8f58-ee32f1180ba8',
    modelId: 'c0b729ea-aa76-47cb-9986-ea75de3dcb10',
  },
  api: {
    cmtUrl: 'https://api-z-dev.sgmarkets.com/aks-uat-2/services/mycases/private/api/v1',
    sgDocsUrl: 'https://api-z-dev.sgmarkets.com/sgdocs/api/v3',
  },
  toggleFeature: {
    isRoutingMatrixActivated: false,
  },
  selfcase: {
    roleId: '50627602-1de3-4709-9acf-61a6a3aaae3d',
    configUrl: 'widget-configurations',
  },
  mycasesBaseUrl: '//mycases-uat-2.sgmarkets.com',
  unauthorizeUrl: 'https://shared.sgmarkets.com/unauthorized/?redirectUri=https://mycases-uat.sgmarkets.com',
};

const selfcaseWidgetConfigProduction: SelfcaseWidgetConfig = {
  allowedFileSize: '10000000',
  allowedFileRequestSize: '10000000',
  SGDocs: {
    workspaceId: 'e0aab377-b745-4365-b03e-8125c751a5df',
    workspaceIdCMT: '48eeb9ff-d3fa-42e4-94b4-bee752e93ee8',
    modelId: '0551331e-d365-43f8-9ba0-b537a0dd2c60',
  },
  api: {
    cmtUrl: 'https://api-z.sgmarkets.com/services/mycases/private/api/v1',
    sgDocsUrl: 'https://api-z.sgmarkets.com/sgdocs/api/v3',
  },
  toggleFeature: {
    isRoutingMatrixActivated: false,
  },
  selfcase: {
    roleId: 'b5d1d1c3-d95c-4c93-ae8f-9b97f5d19950',
    configUrl: 'widget-configurations',
  },
  mycasesBaseUrl: '//mycases.sgmarkets.com',
  unauthorizeUrl: 'https://shared.sgmarkets.com/unauthorized/?redirectUri=https://mycases.sgmarkets.com',
};

export function setSelfcaseWidgetConfiguration(isProduction: boolean) {
  if (isProduction) {
    window.selfcaseWidgetConfig = selfcaseWidgetConfigProduction;
  } else {
    window.selfcaseWidgetConfig = selfcaseWidgetConfigHomologation;
  }
}

export const WIDGET_URL_HOMOLOGATION =
  'https://shared-uat.fr.world.socgen/myc-selfcase-creation/latest/myc-selfcase-creation-widget.js';
export const WIDGET_URL_PRODUCTION =
  'https://shared.sgmarkets.com/myc-selfcase-creation/stable/myc-selfcase-creation-widget.js';
