import React from 'react';
import { registerHelpCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { HELPCENTER_STEPS, ISgwtHelpCenterAction, ISgwtHelpCenterCategory } from '../../sgwt-help-center.types';
import { getCategoryById } from '../../shared/utils';

interface ICategoriesProps extends ITranslatorProps {
  categories: ISgwtHelpCenterCategory[] | null;
  categoryId?: string;
  emitEvent: (name: string, detail: any) => void;
  expanded: boolean;
  next: (action: ISgwtHelpCenterAction) => void;
  step: HELPCENTER_STEPS;
}

export const Categories: React.FC<ICategoriesProps> = ({
  categories = null,
  categoryId,
  emitEvent,
  expanded,
  next,
  step,
  translator,
}: ICategoriesProps) => {
  let elements: ISgwtHelpCenterCategory[] | null = null;
  if (categories && categoryId) {
    const category: ISgwtHelpCenterCategory | null = getCategoryById(categories, categoryId);
    elements =
      category &&
      category.subCategories &&
      category.subCategories instanceof Array &&
      category.subCategories.length !== 0
        ? category.subCategories
        : null;
  } else {
    elements = categories;
  }
  if (elements === null || elements.length === 0) {
    return null;
  }
  const clickCategory = (category: ISgwtHelpCenterCategory) => {
    emitEvent('category', { categoryId: category.id, title: category.title });
    next({
      step: HELPCENTER_STEPS.CATEGORY,
      categoryId: category.id,
      title: category.title,
    });
    registerHelpCenterEvent(`panel.click-${step === HELPCENTER_STEPS.HOME ? 'main-category' : 'category'}`, {
      categoryId: category.id,
      title: category.title,
    });
  };

  return (
    <div>
      <h3 className="h6 mb-3">{translator.translate('commonTopics')}</h3>
      <div className="card-body-categories row">
        {elements.map((category: ISgwtHelpCenterCategory, index: number): JSX.Element => {
          let paddings = '';
          if (expanded) {
            paddings = `${index % 4 === 0 ? '' : 'pl-1 ps-1'} ${index % 4 === 3 ? '' : 'pr-1 pe-1'}`;
          } else {
            paddings = index % 2 === 0 ? 'pr-1 pe-1' : 'pl-1 ps-1';
          }
          return (
            <div key={`category-${category.id}`} className={`col-${expanded ? 3 : 6} ${paddings}`}>
              <button
                className="card mb-2 w-100 text-secondary d-flex flex-center card-bordered card-bordering px-3 text-center sgwt-help-center-category"
                style={{ height: '100px' }}
                type="button"
                aria-label={category.title}
                title={category.title}
                onClick={() => clickCategory(category)}
              >
                {category.icon && (
                  <h3 className="m-0">
                    {category.icon.search(/^pe-/i) === 0 || category.icon.search(/^fa(.)? fa-/i) === 0 ? (
                      <i className={category.icon} aria-hidden="true" style={{ height: '42px', fontSize: '1.4em' }} />
                    ) : (
                      <i className="icon icon-lg" aria-hidden="true">
                        {category.icon}
                      </i>
                    )}
                  </h3>
                )}
                <p className="m-0">{category.title}</p>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
