import React from 'react';
import { registerHelpCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import {
  IHelpCenterRemoteConfiguration,
  ISgwtHelpCenterMessageTopic,
  ISgwtHelpCenterUser,
} from '../../sgwt-help-center.types';
import { IHCMessageContent, IUnityFormParameters } from '../../shared';
import { getValidEmail } from '../../shared/utils';

export const generateMessageContent = (user: ISgwtHelpCenterUser | null, content: string): IHCMessageContent => {
  const username = user && !!user.username ? user.username : '';
  const email = user && !!user.email ? user.email : '';
  const message: IHCMessageContent = {
    content,
    date: new Date().toISOString(),
    email,
    from: email, // same as email, but kept for backward compatibility with customEndpoint old signature...
    subject: '', // will be generated by the topic + content in sgwt-help-center.send() main function
    url: window.location.toString(),
    userAgent: window.navigator.userAgent,
    username,
  };
  return message;
};

export const isReadyToSend = (message: IHCMessageContent, topic: string | null) => {
  return !!message.username && !!message.email && !!getValidEmail(message.email) && !!message.content && !!topic;
};

interface INoUserPanelProps extends ITranslatorProps {
  defaultSendTo: string;
}

export const NoUserPanel = ({ defaultSendTo, translator }: INoUserPanelProps) => {
  let to: string = getValidEmail(defaultSendTo);
  if (to) {
    to = to.split(/,/)[0];
  }
  registerHelpCenterEvent('contact-us.no-user');
  return (
    <div className="d-flex h-100">
      <div className="align-self-center w-100 text-center px-4">
        <p
          dangerouslySetInnerHTML={{
            __html: translator.translate('message.noUser', { mail: to }),
          }}
        />
      </div>
    </div>
  );
};

interface ITopicsChoiceProps extends ITranslatorProps {
  isFormValid: boolean;
  messageTopics: ISgwtHelpCenterMessageTopic[] | null;
  onTopicSelection: (e: any) => void;
  topicId?: string;
  topicSelected?: string;
}

export const TopicSelection = ({
  isFormValid,
  messageTopics,
  onTopicSelection,
  topicId,
  topicSelected,
  translator,
}: ITopicsChoiceProps) => {
  const allTopics: string[] = [translator.translate('message.topicGeneralEnquiry')];
  if (messageTopics && messageTopics.length !== 0) {
    messageTopics.forEach((messageTopic: ISgwtHelpCenterMessageTopic): void => {
      allTopics.push(messageTopic.topic);
    });
  }
  allTopics.push(translator.translate('message.topicReportATechnicalIssue'));
  allTopics.push(translator.translate('message.topicMakeAComplaint'));
  if (topicId) {
    allTopics.push(translator.translate('topicFeedbackLink'));
  }

  return (
    <label
      aria-label={translator.translate('message.topicPlaceholder')}
      htmlFor="topic"
      className="form-group w-100 mb-3"
    >
      <select
        className={`form-control form-select ${!isFormValid && !topicSelected ? 'is-invalid' : ''}`}
        name="topic"
        id="topic"
        onChange={onTopicSelection}
        value={topicSelected}
      >
        <option value="" hidden>
          {translator.translate('message.topicPlaceholder')}
        </option>
        {allTopics.map((option: string) => (
          <option value={option} key={`topic-${option}`}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
};

interface UserFixedFieldProps extends ITranslatorProps {
  user: ISgwtHelpCenterUser;
}

interface UserInputFieldsProps extends ITranslatorProps {
  email: string;
  isFormValid: boolean;
  onChange: (e: any) => void;
  user: ISgwtHelpCenterUser | null;
  username: string;
}

export const UserFixedField = ({ translator, user }: UserFixedFieldProps) => (
  <div className="row mb-2">
    <div className="col">
      <input
        type="text"
        className="form-control text-secondary"
        readOnly={true}
        name="username"
        value={translator.translate('message.signedInAs', { mail: user.email })}
      />
    </div>
  </div>
);

export const UserInputFields = ({ email, isFormValid, onChange, translator, user, username }: UserInputFieldsProps) => {
  if (user) {
    return <UserFixedField translator={translator} user={user} />;
  }
  return (
    <div>
      <div className="form-group">
        <input
          type="text"
          className={`form-control ${!isFormValid && !username ? 'is-invalid' : ''}`}
          name="username"
          onChange={onChange}
          value={username}
          placeholder={translator.translate('message.usernamePlaceholder')}
        />
      </div>

      <div className="form-group">
        <input
          type="email"
          className={`form-control ${!isFormValid && !email ? 'is-invalid' : ''}`}
          name="email"
          onChange={onChange}
          value={email}
          placeholder={translator.translate('message.emailPlaceholder')}
        />
      </div>
    </div>
  );
};

interface MessageDescriptionProps extends ITranslatorProps {
  isAComplaint: boolean;
  isFormValid: boolean;
  message: string;
  onChange: (e: any) => void;
}

export const MessageDescription = ({
  isAComplaint,
  isFormValid,
  message,
  translator,
  onChange,
}: MessageDescriptionProps) => (
  <div className="form-group mb-3">
    <label htmlFor="message-description" className="mb-2">
      {translator.translate('message.description')}
    </label>
    <textarea
      id="message-description"
      className={`form-control ${!isFormValid && !message ? 'is-invalid' : ''}`}
      name="content"
      value={message}
      onChange={onChange}
      rows={isAComplaint ? 9 : 11}
      placeholder={translator.translate('message.commentPlaceholder')}
    />
  </div>
);

interface SendButtonProps extends ITranslatorProps {
  disabled?: boolean;
  onSend: () => void;
  sending: boolean;
}

export const SendButton = ({ disabled, onSend, sending, translator }: SendButtonProps) => (
  <button
    className="btn btn-primary btn-block btn-lg mt-auto"
    type="button"
    onClick={onSend}
    disabled={disabled || sending}
  >
    {sending && <span className="spinner-grow spinner-grow-xs bg-lvl1 mr-1 me-1" />}
    {translator.translate('message.sendButtonLabel')}
  </button>
);

export function getChoicesForField<T>(
  widgetConfig: IUnityFormParameters | null,
  remoteConfig: IHelpCenterRemoteConfiguration | null,
  medium: 'impulse',
  field: string,
): T[] | null {
  // eslint-disable-next-line no-prototype-builtins
  if (widgetConfig && widgetConfig.hasOwnProperty(field)) {
    const widgetValue = (widgetConfig as any)[field];
    return Array.isArray(widgetValue) ? widgetValue : [widgetValue];
  }
  const remoteField = `${medium}${field[0].toUpperCase()}${field.slice(1)}`;
  return remoteConfig &&
    remoteConfig.communication &&
    // eslint-disable-next-line no-prototype-builtins
    remoteConfig.communication.hasOwnProperty(remoteField)
    ? (remoteConfig.communication as any)[remoteField]
    : null;
}

export function getFieldDefaultValue<T>(choices: T[] | null, defaultValue: T): T {
  return choices === null || choices.length === 0 || choices.length > 1 ? defaultValue : choices[0];
}

export function getFieldFirstValue<T>(choices: T[] | null, defaultValue: T): T {
  return choices === null || choices.length === 0 ? defaultValue : choices[0];
}

export function hasChoiceForField<T>(choices: T[] | null) {
  return choices !== null && choices.length > 1;
}
