import { WidgetConfiguration } from '@sg-widgets/shared-core';
import {
  addAuthenticationInfoInRequestHeader,
  checkResponseStatus,
  hasGrantedScope,
  WidgetEnvironment,
} from '../../../../common/sgwt-widgets-utils';
import { MY_CASES_ENDPOINTS } from '../../shared';

type MyCasesStatus = 'New' | 'Pending' | 'In progress' | 'Resolved'; // TODO Check list of statuses

export interface MyCasesResult {
  id: string;
  metaData: {
    caseLabel: string;
    shortStatus: MyCasesStatus;
    creationDateTime: string;
    resolutionDateTime: string;
  };
  content: {
    activity: string;
    subActivity: string;
    issueType: string;
    customer: {
      bdrId: string;
      bdrLevel: string;
      displayName: string;
    };
  };
  userMetaData: {
    lastSeenDateTime: string;
    lastAlertDateTime: string;
    alertCount: number;
    isContact: boolean;
    isCreator: boolean;
    isFavourite: boolean;
    isShared: boolean;
    latestVisibleInteraction: {
      type: string;
      id: string;
    };
  };
  sla: {
    targetDateTime: string;
  };
}

export interface MyCasesResponse {
  hasMoreResults: boolean;
  results: MyCasesResult[];
}

// Check several things to see if the user can see the "Open cases" of MyCases:
//  - The SG Connect token has the scope "api.sgm-mycases.v1"
export function hasAccessToMyCases(widgetConfiguration: WidgetConfiguration) {
  // Check the scopes
  return hasGrantedScope(widgetConfiguration, 'api.sgm-mycases.v1');
}

// Format 2024-01-01T09:42:25Z (ISO without ms)
// const formatDate = (date: Date) => date.toISOString().split('.')[0] + 'Z';

export async function callMyCasesAPI(
  widgetConfiguration: WidgetConfiguration,
  openCases: boolean,
): Promise<MyCasesResponse> {
  const search = 'MY_CASES';
  const scope = openCases ? 'OPEN' : 'RESOLVED';
  const base = MY_CASES_ENDPOINTS[widgetConfiguration.environment as WidgetEnvironment].api;
  const url = `${base}?predefinedSearch=${search}&scope=${scope}&populateFields=userMetaData,metaData,content`;

  const options = addAuthenticationInfoInRequestHeader(
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify({}),
    },
    widgetConfiguration,
  );

  return fetch(url, options)
    .then(checkResponseStatus)
    .then((response: any) => response.json());
}
