import React, { useState } from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { WidgetEnvironment } from '../../../../common/sgwt-widgets-utils';
import { HELPCENTER_STEPS, ISgwtHelpCenterAction } from '../../sgwt-help-center.types';
import { MY_CASES_ENDPOINTS } from '../../shared';
import { callMyCasesAPI, MyCasesResponse, MyCasesResult } from './mycases.utils';

interface SupportCasesListProps extends ITranslatorProps {
  next: (action: ISgwtHelpCenterAction) => void;
  openCases: MyCasesResponse | null;
  step: HELPCENTER_STEPS;
  widgetConfiguration: WidgetConfiguration;
}

interface SupportTicketProps {
  formatter: Intl.DateTimeFormat;
  portalUrl: string;
  ticket: MyCasesResult;
}

const STATUS_COLORS: Record<string, string> = {
  Pending: 'text-warning',
  'In progress': 'text-success',
  Resolved: 'text-secondary',
};

const SupportTicket = ({ formatter, portalUrl, ticket }: SupportTicketProps) => {
  const color = STATUS_COLORS[ticket.metaData.shortStatus] ?? 'text-info';
  const { lastAlertDateTime } = ticket.userMetaData;
  const date = lastAlertDateTime ? formatter.format(new Date(lastAlertDateTime)) : null;
  const url = `${portalUrl}/#/case/${ticket.id}`;
  return (
    <li className="border-bottom border-opacity-40">
      <a className="list-group-item list-group-item-action btn-link px-4" href={url} target="_blank" rel="noreferrer">
        <div className="d-flex flex-column text-truncate w-100">
          <div className="d-flex text-truncate">
            <div className="fw-medium text-truncate mb-4px">{ticket.metaData.caseLabel}</div>
          </div>
          {/* <div className="d-flex align-items-center fs-12 mb-4px">
            <div className="text-secondary text-truncate">{ticket.metaData.caseLabel}</div>
          </div> */}
          <div className="d-flex fs-12">
            {date && <div className="text-secondary me-4px">{date} ·</div>}
            <div className={`${color} fw-medium me-4px`}>{ticket.metaData.shortStatus}</div>
          </div>
        </div>
      </a>
    </li>
  );
};

interface DisplayListProps extends ITranslatorProps {
  loading: boolean;
  showError: boolean;
  tickets: MyCasesResult[];
  widgetConfiguration: WidgetConfiguration;
}

const DisplayList = ({ loading, showError, tickets, translator, widgetConfiguration }: DisplayListProps) => {
  if (loading) {
    return <div className="spinner-grow blinker blinker-lg text-nowrap m-4">{translator.translate('loading')}</div>;
  }

  if (showError) {
    return (
      <div className="text-center text-warning mt-4 px-4">
        <SvgIcon type="error" className="icon me-1" width={18} height={18} />
        <span>{translator.translate('supportCases.error')}</span>
      </div>
    );
  }

  if (tickets.length === 0) {
    return <div className="text-secondary m-4">{translator.translate('supportCases.empty')}</div>;
  }

  const portalUrl = MY_CASES_ENDPOINTS[widgetConfiguration.environment as WidgetEnvironment].portal;
  const formatter = new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium' });
  return (
    <ul className="list-group list-group-flush">
      {tickets.map((ticket) => (
        <SupportTicket key={ticket.id} ticket={ticket} formatter={formatter} portalUrl={portalUrl} />
      ))}
    </ul>
  );
};

export const SupportCasesList = (props: SupportCasesListProps) => {
  const [tab, setTab] = useState<'open' | 'resolved'>('open');
  const [resolvedCases, setResolvedCases] = useState<MyCasesResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const switchTab = () => {
    // The first time we open the "Resolved" tab, we call the API to retrieve the data
    if (tab === 'open' && !resolvedCases && !error) {
      setLoading(true);
      callMyCasesAPI(props.widgetConfiguration, false)
        .then((response) => {
          setResolvedCases(response);
        })
        .catch((err) => {
          console.error('Could not retrieve Open Cases from CMT', err);
          setResolvedCases(null);
          setError(err.message);
        })
        .finally(() => {
          setLoading(false);
          setTab('resolved');
        });
    } else {
      setTab(tab === 'open' ? 'resolved' : 'open');
    }
  };

  const tickets = (tab === 'open' ? props.openCases?.results : resolvedCases?.results) ?? [];
  const showError = tab === 'resolved' && !!error;

  return (
    <div className="mb-3 mx-n4">
      <ul className="nav nav-underline nav-md border-bottom px-4">
        <li className="nav-item">
          <button className={`nav-link ${tab === 'open' && 'active'}`} onClick={switchTab}>
            {props.translator.translate('supportCases.tab.open')}
          </button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${tab === 'resolved' && 'active'}`} onClick={switchTab}>
            {props.translator.translate('supportCases.tab.resolved')}
          </button>
        </li>
      </ul>

      <div className="mb-24px">
        <DisplayList loading={loading} showError={showError} tickets={tickets} {...props} />
      </div>
    </div>
  );
};
