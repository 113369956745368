import { WidgetConfiguration } from '@sg-widgets/shared-core';
import {
  addAuthenticationInfoInRequestHeader,
  checkResponseStatus,
  emptyObject,
  parseResponseAsJson,
} from '../../../common/sgwt-widgets-utils';
import { IHelpCenterRemoteConfiguration } from '../sgwt-help-center.types';
import { CommunicationType, IHCMessageContent } from './communication.types';
import { getCommunicationEndpoint } from './help-center.endpoints';

const SEPARATOR = '\n\n--------------------------------------------------------------------------------\n\n';

function getAdditionalMessageInformation(additionalMessageInformation: { [key: string]: any } = {}) {
  if (emptyObject(additionalMessageInformation)) {
    return '';
  }
  let additional = SEPARATOR + 'ADDITIONAL MESSAGE INFORMATION\n\n';
  Object.keys(additionalMessageInformation).forEach((key: string): void => {
    additional += `${key}: ${additionalMessageInformation[key]}\n`;
  });
  return additional;
}

export function generateCommunicationContent(
  message: IHCMessageContent,
  topic: string,
  additionalMessageInformation: { [key: string]: any } = {},
): { content: string; contentWithContext: string } {
  const dt: Date = new Date();
  const content = `User: ${message.username || ''}\n\nEmail: ${message.email || ''}\n\nTopic: ${
    topic || ''
  }\n\nComment:\n${message.content}${getAdditionalMessageInformation(additionalMessageInformation)}`;

  const context = `CONTEXT\n\nDateTime: ${dt.toUTCString()}\n\nURL: ${message.url || 'n/a'}\n\nUserAgent: ${
    message.userAgent || 'n/a'
  }\n\nIP: ${message.ip || ''}`;
  const contentWithContext = `${content}${SEPARATOR}${context}`;

  return { content, contentWithContext };
}

export async function sendMessage(
  widgetConfiguration: WidgetConfiguration,
  endpoint: string,
  message: IHCMessageContent,
) {
  const options: any = addAuthenticationInfoInRequestHeader(
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...message,
        // We transform the content of the message because:
        // 1. encodeURIComponent => to correctly decode the accents on the NodeJS side - https://stackoverflow.com/a/30106551/26457
        // 2. btoa => mainly to pass possible WAF checks that may block the request otherwise...
        content: btoa(encodeURIComponent(message.content)),
      }),
    },
    widgetConfiguration,
  );

  return fetch(endpoint, options).then(checkResponseStatus).then(parseResponseAsJson);
}

export function isCommunicationOfType(
  helpCenterConfiguration: IHelpCenterRemoteConfiguration | null,
  comm: CommunicationType,
) {
  return (
    !!helpCenterConfiguration &&
    !!helpCenterConfiguration.communication &&
    helpCenterConfiguration.communication.medium === comm
  );
}

export async function doNotNotifyAgain(widgetConfiguration: WidgetConfiguration, applicationId: string) {
  const endpoint = `${getCommunicationEndpoint('feedback', widgetConfiguration)}/${applicationId}/doNotNotify`;
  const options: any = addAuthenticationInfoInRequestHeader({ method: 'POST', headers: {} }, widgetConfiguration);
  return fetch(endpoint, options).then(checkResponseStatus).then(parseResponseAsJson);
}
