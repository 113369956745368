import React from 'react';
import { SidePanel } from '../../../common/components/SidePanel';
import { Translator } from '../../../common/sgwt-i18n';
import { WidgetEnvironment } from '../../../common/sgwt-widgets-utils';
import {
  HELPCENTER_QUIT,
  HELPCENTER_STEPS,
  HelpCenterFormInput,
  IHelpCenterRemoteConfiguration,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterKnowledge,
  ISgwtHelpCenterMessageTopic,
  ISgwtHelpCenterTopic,
  ISgwtHelpCenterUser,
} from '../sgwt-help-center.types';
import { CommunicationType, IHCMessageContent, isCommunicationOfType, IUnityFormParameters } from '../shared';
import Chat from './form/Chat';
import { FeedbackForm, MailForm, MyCasesForm, UnityForm } from './form';
import { PanelHeader } from './PanelHeader';

interface FormPanelProps {
  allowScreenshot: boolean;
  applicationId?: string;
  cancelChat: () => void;
  changeExpandableState: () => void;
  chatContext?: string;
  close: () => void;
  defaultSendTo: string;
  environment: WidgetEnvironment;
  expanded: boolean;
  feedbackContext: string;
  formValues?: HelpCenterFormInput | null;
  hasForm: boolean;
  hasMessageOnly: boolean;
  helpCenterConfiguration: IHelpCenterRemoteConfiguration | null;
  unityConfiguration: IUnityFormParameters | null;
  isLoading: boolean;
  isSending: boolean;
  knowledge: ISgwtHelpCenterKnowledge | null;
  messageTemplate: string | null;
  messageTopics: ISgwtHelpCenterMessageTopic[] | null;
  next: (action: ISgwtHelpCenterAction) => void;
  noConsole: boolean;
  previous: (nb?: number) => void;
  quit: HELPCENTER_QUIT;
  reset: () => void;
  selectedRating: number;
  send: (type: CommunicationType, message: IHCMessageContent, topic: string) => void;
  step: HELPCENTER_STEPS;
  topicId?: string;
  translator: Translator;
  user: ISgwtHelpCenterUser | null;
}

/**
 * Define which "Contact us" form to display.
 * Normally, it is taken from the Help-Center configuration provided by the Services Admin portal,
 * but it can be overriden when `.form({ medium: '...' })` is called.
 */
const defineForm = (
  helpCenterConfiguration: IHelpCenterRemoteConfiguration | null,
  formValues?: HelpCenterFormInput | null,
): CommunicationType => {
  if (formValues?.medium) {
    return formValues.medium;
  }
  if (
    isCommunicationOfType(helpCenterConfiguration, 'unity') ||
    isCommunicationOfType(helpCenterConfiguration, 'impulse')
  ) {
    return 'unity';
  }
  if (isCommunicationOfType(helpCenterConfiguration, 'mycases')) {
    return 'mycases';
  }
  return 'mail';
};

export const FormPanel = (props: FormPanelProps) => {
  const { helpCenterConfiguration, knowledge, step } = props;
  if (step === HELPCENTER_STEPS.CHAT) {
    if (customElements.get('sgwt-sgbot-ms')) {
      return (
        <SidePanel
          widget="sgwt-help-center"
          onClose={props.close}
          expanded={props.expanded}
          title={props.translator.translate('panel.bot')}
        >
          <PanelHeader {...props} hasSearch={false} />
          <div className="card-body pt-0">
            <Chat {...props} />
          </div>
        </SidePanel>
      );
    } else {
      console.error('sgwt-sgbot-ms is not available');
      return null;
    }
  }
  if (step === HELPCENTER_STEPS.FEEDBACK) {
    return <FeedbackForm {...props} />;
  }

  const topics: ISgwtHelpCenterTopic[] | null =
    knowledge && knowledge.topics && knowledge.topics instanceof Array ? knowledge.topics : null;

  switch (defineForm(helpCenterConfiguration, props.formValues)) {
    case 'unity':
      return <UnityForm {...props} topics={topics} unityConfiguration={props.unityConfiguration!} />;
    case 'mycases':
      return <MyCasesForm {...props} />;
    default:
      return <MailForm {...props} topics={topics} />;
  }
};
