import React, { useEffect, useState } from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { HELPCENTER_STEPS, ISgwtHelpCenterAction } from '../../sgwt-help-center.types';
import { callMyCasesAPI, MyCasesResponse } from './mycases.utils';
import { SupportCasesList } from './SupportCasesList';

interface SupportCasesProps extends ITranslatorProps {
  next: (action: ISgwtHelpCenterAction) => void;
  step: HELPCENTER_STEPS;
  widgetConfiguration: WidgetConfiguration;
}

export const SupportCases = (props: SupportCasesProps) => {
  const [openCases, setOpenCases] = useState<MyCasesResponse | null>(null);
  const [error, setError] = useState<string | null>(null);

  const seeDetails = () => {
    props.next({
      step: HELPCENTER_STEPS.SUPPORT_CASES,
    });
  };

  useEffect(() => {
    callMyCasesAPI(props.widgetConfiguration, true)
      .then((response) => {
        setOpenCases(response);
      })
      .catch((err) => {
        console.error('Could not retrieve Open Cases from CMT', err);
        setOpenCases(null);
        setError('Error while calling MyCases API');
      });
  }, [props.widgetConfiguration]);

  if (props.step === HELPCENTER_STEPS.HOME) {
    return (
      <div className="mb-3">
        <h3 className="h6 mb-3">{props.translator.translate('supportCases.title')}</h3>
        <div className="col-12">
          <button
            className="card card-bordered card-bordering w-100 text-secondary d-flex justify-content-between flex-row p-3"
            type="button"
            disabled={!openCases}
            onClick={seeDetails}
          >
            <span className="text-secondary">{props.translator.translate('supportCases.openCases')}</span>
            {!openCases && !error && <span className="spinner spinner-sm" />}
            {openCases && (
              <span className="fw-bold">
                {openCases.results.length}
                {openCases.hasMoreResults ? '+' : ''}
              </span>
            )}
            {error && <SvgIcon type="error" className="icon text-warning" width={18} height={18} />}
          </button>
        </div>
      </div>
    );
  }

  if (props.step === HELPCENTER_STEPS.SUPPORT_CASES) {
    return <SupportCasesList {...props} openCases={openCases} />;
  }
  return null;
};
