import React, { useEffect, useState } from 'react';
import { SidePanel } from '../../../../common/components/SidePanel';
import { registerHelpCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { emptyObject, WidgetEnvironment } from '../../../../common/sgwt-widgets-utils';
import {
  HELPCENTER_QUIT,
  HELPCENTER_STEPS,
  HelpCenterFormInput,
  IHelpCenterRemoteConfiguration,
  ISgwtHelpCenterAction,
  ISgwtHelpCenterKnowledge,
  ISgwtHelpCenterMessageTopic,
  ISgwtHelpCenterTopic,
  ISgwtHelpCenterUser,
  UnityFormInput,
} from '../../sgwt-help-center.types';
import {
  CommunicationType,
  IHCMessageContent,
  IHCUnityContent,
  IUnityFormParameters,
  UNITY_IMPACTED_ENVIRONMENT_LABELS,
  UnityImpactedEnvironment,
  unityImpactedEnvironmentValues,
  UnityType,
  unityTypeValues,
  Value_123,
  values123,
} from '../../shared';
import { findIP, isMessageTopicIsAComplaint } from '../../shared/utils';
import { PanelHeader } from '../PanelHeader';
import {
  generateMessageContent,
  getChoicesForField,
  getFieldDefaultValue,
  hasChoiceForField,
  isReadyToSend,
  MessageDescription,
  NoUserPanel,
  SendButton,
  TopicSelection,
  UserFixedField,
} from './message.shared';
import { getFieldFirstValue, Screenshot } from '.';

interface UnityFormProps extends ITranslatorProps {
  changeExpandableState: () => void;
  close: () => void;
  defaultSendTo: string;
  environment: WidgetEnvironment;
  expanded: boolean;
  formValues?: HelpCenterFormInput | null;
  hasMessageOnly: boolean;
  helpCenterConfiguration: IHelpCenterRemoteConfiguration | null;
  unityConfiguration: IUnityFormParameters;
  isLoading: boolean;
  isSending: boolean;
  knowledge: ISgwtHelpCenterKnowledge | null;
  messageTemplate: string | null;
  messageTopics: ISgwtHelpCenterMessageTopic[] | null;
  next: (action: ISgwtHelpCenterAction) => void;
  previous: (nb?: number) => void;
  quit: HELPCENTER_QUIT;
  reset: () => void;
  send: (type: CommunicationType, message: IHCMessageContent, topic: string) => void;
  step: HELPCENTER_STEPS;
  topicId?: string;
  topics: ISgwtHelpCenterTopic[] | null;
  user: ISgwtHelpCenterUser | null;
}

function getChoices<T>(props: UnityFormProps, field: string, allowedValues?: T[]): T[] | null {
  const choices: T[] | null = getChoicesForField(
    props.unityConfiguration,
    props.helpCenterConfiguration,
    'impulse',
    field,
  );
  if (choices === null) {
    return null;
  }
  return allowedValues ? choices.filter((c) => allowedValues.indexOf(c) > -1) : choices;
}

export const UnityForm = (props: UnityFormProps) => {
  const defaultValues: UnityFormInput = (props.formValues as UnityFormInput) || {};
  const currentEnvironment: UnityImpactedEnvironment = props.environment === 'production' ? 'prod' : 'homo';

  const [dirty, setDirty] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [ipAddress, setIpAddress] = useState('n/a');
  const [topic, setTopic] = useState(
    defaultValues.topic ? defaultValues.topic : props.topicId ? props.translator.translate('topicFeedbackLink') : '',
  );
  const [content, setContent] = useState(defaultValues.content || props.messageTemplate || '');
  const [assignmentGroup, setAssignmentGroup] = useState<string>(
    defaultValues.assignmentGroup || getFieldFirstValue(getChoices<string>(props, 'assignmentGroup'), ''),
  );
  const [caseType, setCaseType] = useState<UnityType>(
    defaultValues.caseType ||
      getFieldDefaultValue(getChoices<UnityType>(props, 'caseType', unityTypeValues), 'request'),
  );
  const [cmdbCi, setCmdbCi] = useState<string>(getFieldFirstValue(getChoices<string>(props, 'cmdbCi'), ''));
  const [impact, setImpact] = useState<Value_123>(
    getFieldDefaultValue(getChoices<Value_123>(props, 'impact', values123), '2'),
  );
  const [impactedEnvironment, setImpactedEnvironment] = useState<UnityImpactedEnvironment>(
    getFieldDefaultValue(
      getChoices<UnityImpactedEnvironment>(props, 'impactedEnvironment', unityImpactedEnvironmentValues),
      currentEnvironment,
    ),
  );
  const [symptomCategory, setSymptomCategory] = useState<string>(
    getFieldFirstValue(getChoices<string>(props, 'symptomCategory'), ''),
  );
  const [urgency, setUrgency] = useState<Value_123>(
    getFieldDefaultValue(getChoices<Value_123>(props, 'urgency', values123), '3'),
  );
  const [whyHighImpact, setWhyHighImpact] = useState<string>('');
  const [screenshot, setScreenshot] = useState<string | null>(null);

  useEffect(() => {
    findIP.then((ipAddress) => setIpAddress(ipAddress));
  }, []);

  useEffect(() => {
    registerHelpCenterEvent('contact-us.impulse-form');
  }, []);

  /**
   * We disable the "click out" feature (i.e. a click outside the SidePanel closes it) if the form
   * is dirty (i.e. the user started to fill it).
   */
  const handleClose = (fromClickOut: boolean) => {
    if (!fromClickOut || !dirty) {
      props.close();
    }
  };

  if (emptyObject(props.user)) {
    return (
      <SidePanel
        widget="sgwt-help-center"
        onClose={handleClose}
        expanded={props.expanded}
        title={props.translator.translate('panel.contact-us')}
      >
        <PanelHeader {...props} hasSearch={false} />
        <NoUserPanel defaultSendTo={props.defaultSendTo} translator={props.translator} />
        <div className="card-footer px-4 py-3">
          <button className="btn btn-primary btn-block btn-lg" onClick={props.close}>
            {props.translator.translate('message.close')}
          </button>
        </div>
      </SidePanel>
    );
  }

  const isAComplaint: boolean = isMessageTopicIsAComplaint(
    topic || '',
    props.translator.translate('message.topicMakeAComplaint'),
  );

  // Get possibles choices from remote configuration & widget configuration...
  const caseTypeDisabled = !hasChoiceForField(getChoices(props, 'caseType', unityTypeValues));

  const impactChoices = getChoices(props, 'impact', values123);
  const impactHidden = !hasChoiceForField(impactChoices);
  const impactSelected = impact;

  const urgencyChoices = getChoices(props, 'urgency', values123);
  const urgencyHidden = !hasChoiceForField(urgencyChoices);
  const urgencySelected = urgency;

  let environmentsChoices =
    getChoices<UnityImpactedEnvironment>(props, 'impactedEnvironment', unityImpactedEnvironmentValues) || [];
  if (environmentsChoices.length === 0) {
    environmentsChoices = [currentEnvironment];
  }

  const assignmentGroupChoices = getChoices<string>(props, 'assignmentGroup');
  const cmdbCiChoices = getChoices<string>(props, 'cmdbCi');
  const symptomCategoryChoices = getChoices<string>(props, 'symptomCategory');

  const valueChanged = (e: any) => {
    const { value } = e.target;
    switch (e.target.name) {
      case 'topic':
        setTopic(value);
        break;
      case 'content':
        setContent(value);
        break;
      case 'caseType':
        setCaseType(value);
        break;
      case 'cmdbCi':
        setCmdbCi(value);
        break;
      case 'assignmentGroup':
        setAssignmentGroup(value);
        break;
      case 'symptomCategory':
        setSymptomCategory(value);
        break;
      case 'impact':
        setImpact(value);
        break;
      case 'urgency':
        setUrgency(value);
        break;
      case 'whyHighImpact':
        setWhyHighImpact(value);
        break;
      case 'impactedEnvironment':
        setImpactedEnvironment(value);
        break;
    }
    setDirty(true);
  };

  const screenCaptured = (screen: string | null) => {
    setScreenshot(screen);
    setDirty(true);
  };

  const send = () => {
    const message: IHCUnityContent = {
      ...generateMessageContent(props.user, content),
      ...props.unityConfiguration,
      assignmentGroup,
      caseType,
      cmdbCi,
      impact,
      impactedEnvironment,
      symptomCategory,
      urgency,
      whyHighImpact,
      ip: ipAddress,
      screenshot: screenshot || undefined,
    };
    const ready = isReadyToSend(message, topic) && (impact !== '1' || !!whyHighImpact);
    if (ready) {
      props.send('unity', message, topic);
    } else {
      setIsValid(false);
    }
  };

  return (
    <SidePanel
      widget="sgwt-help-center"
      onClose={handleClose}
      expanded={props.expanded}
      title={props.translator.translate('panel.contact-us')}
    >
      <PanelHeader {...props} hasSearch={false} />
      <div className="card-body px-4 py-0">
        <UserFixedField translator={props.translator} user={props.user!} />

        <TopicSelection
          isFormValid={isValid}
          messageTopics={props.messageTopics}
          onTopicSelection={valueChanged}
          topicId={props.topicId}
          topicSelected={topic}
          translator={props.translator}
        />

        {isAComplaint && (
          <span className="help-block d-block mb-3">
            {props.translator.translate('message.topicPlaceholderMakeAComplaintHelper')}
          </span>
        )}

        {/* CASE TYPE - Request or Incident */}
        <div className="form-group">
          <label htmlFor="caseType" className="mb-2">
            {props.translator.translate('impulse.caseType')}
          </label>
          <select
            className="form-control form-select mb-3"
            id="caseType"
            name="caseType"
            onChange={valueChanged}
            disabled={caseTypeDisabled}
            value={caseType}
          >
            <option value="request">{props.translator.translate('impulse.typeRequest')}</option>
            <option value="incident">{props.translator.translate('impulse.typeIncident')}</option>
          </select>
        </div>

        {/* CMDBCI - in case we let the choice to the user */}
        {cmdbCiChoices && cmdbCiChoices.length > 1 && (
          <div className="form-group">
            <label htmlFor="cmdbCi" className="mb-2">
              {props.translator.translate('impulse.cmdbCi')}
            </label>
            <select
              className="form-control form-select"
              id="cmdbCi"
              name="cmdbCi"
              onChange={valueChanged}
              value={cmdbCi}
            >
              {cmdbCiChoices.map((ci: string) => (
                <option value={ci} key={ci}>
                  {ci}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* ASSIGNMENT GROUP - in case we let the choice to the user */}
        {assignmentGroupChoices && assignmentGroupChoices.length > 1 && (
          <div className="form-group">
            <label htmlFor="assignmentGroup" className="mb-2">
              {props.translator.translate('impulse.assignmentGroup')}
            </label>
            <select
              className="form-control form-select"
              id="assignmentGroup"
              name="assignmentGroup"
              onChange={valueChanged}
              value={assignmentGroup}
            >
              {assignmentGroupChoices.map((group: string) => (
                <option value={group} key={group}>
                  {group}
                </option>
              ))}
            </select>
          </div>
        )}

        <MessageDescription
          translator={props.translator}
          isAComplaint={isAComplaint}
          isFormValid={isValid}
          message={content}
          onChange={valueChanged}
        />

        {/* IMPACT LEVEL - "1" (High) to "3" (Low) */}
        {!impactHidden && (
          <div className="form-group mb-3">
            <label htmlFor="impact" className="mb-2">
              {props.translator.translate('impulse.impact')}
            </label>
            <div
              className="btn-group btn-group-toggle w-100"
              data-toggle="buttons"
              role="group"
              aria-label="toggle buttons single select"
            >
              {['High', 'Moderate', 'Low'].map((level, index) => {
                const str = `${index + 1}`;
                if (impactChoices !== null && impactChoices.indexOf(str as Value_123) === -1) {
                  return null;
                }
                return (
                  <label
                    key={`impact-${level}`}
                    className={`btn btn-toggle-info ${impactSelected === str ? 'active' : 'border'}`}
                  >
                    <input
                      type="radio"
                      name="impact"
                      className="btn-check"
                      onClick={valueChanged}
                      value={`${index + 1}`}
                    />
                    {props.translator.translate(`impulse.level${level}`)}
                  </label>
                );
              })}
            </div>
          </div>
        )}
        {/* For impact "High", ask for a justification */}
        {!impactHidden && impactSelected === '1' && (
          <div className="form-group mb-3">
            <label htmlFor="message-description" className="mb-2">
              {props.translator.translate('impulse.whyHighImpact')}
            </label>
            <textarea
              id="message-description"
              className={`form-control ${!isValid && !whyHighImpact ? 'is-invalid' : ''}`}
              name="whyHighImpact"
              value={whyHighImpact}
              onChange={valueChanged}
              rows={6}
            />
          </div>
        )}

        {/* URGENCY LEVEL - "1" (High) to "3" (Low) */}
        {!urgencyHidden && (
          <div className="form-group mb-3">
            <label htmlFor="impact" className="mb-2">
              {props.translator.translate('impulse.urgency')}
            </label>
            <div
              className="btn-group btn-group-toggle w-100"
              data-toggle="buttons"
              role="group"
              aria-label="toggle buttons single select"
            >
              {['High', 'Moderate', 'Low'].map((level, index) => {
                const str = `${index + 1}`;
                if (urgencyChoices !== null && urgencyChoices.indexOf(str as Value_123) === -1) {
                  return null;
                }
                return (
                  <label
                    key={`urgency-${level}`}
                    className={`btn btn-toggle-info ${urgencySelected === str ? 'active' : 'border'}`}
                  >
                    <input
                      type="radio"
                      name="urgency"
                      className="btn-check"
                      onClick={valueChanged}
                      value={`${index + 1}`}
                    />
                    {props.translator.translate(`impulse.level${level}`)}
                  </label>
                );
              })}
            </div>
          </div>
        )}

        {/* SYMPTOM CATEGORY - in case we let the choice to the user */}
        {symptomCategoryChoices && symptomCategoryChoices.length > 1 && (
          <div className="form-group">
            <label htmlFor="symptomCategory" className="mb-2">
              {props.translator.translate('impulse.symptomCategory')}
            </label>
            <select
              className="form-control form-select"
              id="symptomCategory"
              name="symptomCategory"
              onChange={valueChanged}
              value={symptomCategory}
            >
              {symptomCategoryChoices.map((symptom: string) => (
                <option value={symptom} key={symptom}>
                  {symptom}
                </option>
              ))}
            </select>
          </div>
        )}

        {environmentsChoices.length > 1 && (
          <div className="form-group mb-3">
            <label htmlFor="impactedEnvironment" className="mb-2">
              {props.translator.translate('impulse.impactedEnvironment')}
            </label>
            <select
              className="form-control form-select"
              id="impactedEnvironment"
              name="impactedEnvironment"
              onChange={valueChanged}
              value={impactedEnvironment}
            >
              {environmentsChoices.map((env: UnityImpactedEnvironment) => (
                <option value={env} key={env}>
                  {UNITY_IMPACTED_ENVIRONMENT_LABELS[env]}
                </option>
              ))}
            </select>
          </div>
        )}

        {props.helpCenterConfiguration?.communication?.allowScreenshot && (
          <Screenshot
            configuration={props.helpCenterConfiguration}
            onCapture={screenCaptured}
            screenshot={screenshot}
            translator={props.translator}
          />
        )}
      </div>
      <div className="card-footer px-4 py-3">
        <SendButton translator={props.translator} onSend={send} sending={props.isSending} />
      </div>
    </SidePanel>
  );
};
