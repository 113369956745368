import { useEffect, useRef, useState } from 'react';

const isWidgetScriptPresent = (widget: string, url: string): boolean => {
  const tag = document.querySelector(`script[src*="${url}"`);
  return !!window.customElements.get(widget) || !!tag;
};

/**
 * Try to load a specific widget from a given URL, except if the widget is already loaded.
 */
const loadWidget = (widget: string, url: string, onLoadedCallback?: () => void) => {
  const element = document.createElement('script');
  element.type = 'text/javascript';
  element.src = url;
  element.id = `${widget}-script`;
  element.onload = () => {
    onLoadedCallback?.();
  };
  document.body.appendChild(element);
};

/**
 * useEffect() is executed twice on DEV with StrictMode.
 * Since we need to load the widget resource only once, we use a ref to keep track of the first execution.
 * On second call, the `ref.current` is true and we skip the widget loading.
 */
export function useLoadWidget(widget: string, url: string) {
  const ref = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      if (!isWidgetScriptPresent(widget, url)) {
        setIsLoading(true);
        loadWidget(widget, url, () => {
          setIsLoading(false);
        });
      }
    }
    return () => {
      ref.current = true;
    };
  }, [widget, url]);

  return { isLoading };
}
